<template>
  <div class="login">
    <LoginComp />
  </div>
</template>

<script>
import LoginComp from "@/components/Login/LoginComp.vue";

export default {
  name: "Login",
  components: {
    LoginComp,
  },
};
</script>

import axios from 'axios'
import config from './config/config'

const instance = axios.create({})

// Declarando rota default
instance.defaults.baseURL = config.apiURL

// Interceptando requisição
instance.interceptors.request.use(config => {
    const token = localStorage.getItem('token')
    config.headers.common['Authorization'] = `Bearer ${token}`
    return config
});

export default instance

<template>
  <div class="navbar d-flex justify-space-between align-center" elevation="1">

    <div class="box">
      <img src="../assets/LogoSGC.svg" alt="">
      <h3>SGCS</h3>
      <span>|</span>
      <h5>Sistema de Gestão dos Consórcios de Saúde</h5>
    </div>
    <nav-menu-comp />
  </div>
</template>

<script>

import NavMenuComp from '../components/NavMenuComp'

export default {
  data: () => ({
    selectedItem: 2,
    closeOnClick: true,
  }),
  components: {
    'nav-menu-comp': NavMenuComp
  },
  name: "NavbarComp",

  // created(){
  //   this.initialize()
  // },

  // methods: {
  //   initialize() {
  //     console.log(localStorage.getItem('id'));
  //   }
  // }
};
</script>

<style scoped>
.navbar {
  display: flex;

  width: 100%;
  height: 55px;

  background-color: #18e79a;
  /* background-image: linear-gradient(281deg, #53cedb 0%, #18e79a 100%); */

  margin-bottom: 25px;
  padding: 0 25px;
}

.navbar > .box{
  display: flex;
  align-items: center;
  justify-items: center;
}

.navbar > .box > .logo{
  border: 1px solid black;
  border-radius: 10px
}

.navbar > .box > img{
  height: 30px;
  width: 30px;
  margin-right: 7px;
} 

.navbar > .box >  h3 {
  font-family: "Archivo Black", sans-serif;
  font-size: 20px;
  line-height: 26px;
  color: black;
  margin-right: 7px;
}

.navbar > .box >  span {
  margin-right: 7px;
}

.navbar > .box >  h5 {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: black;
}
</style>

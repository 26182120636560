<template>
  <div class="bg">
    <navbar-comp />
    <main-comp />
  </div>
</template>

<script>
import NavbarComp from "../components/NavbarComp";
import MainComp from "../components/Consorcios/MainComp.vue";
export default {
  name: "Consorcios",

  components: {
    "navbar-comp": NavbarComp,
    "main-comp": MainComp,
  },
};
</script>


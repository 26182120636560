export default {
    // DESENV
    // apiURL: 'http://desenv.consorciosapi.saude.ba.gov.br/api',
    //  captchaURL: 'http://desenv.consorciosapi.saude.ba.gov.br/captcha/api/default',
    //  baseUrlSite: 'http://desenv.consorciosapi.saude.ba.gov.br/',
    // docURL: 'http://desenv.consorciosapi.saude.ba.gov.br/api/document',
    // docItemsURL: 'http://desenv.consorciosapi.saude.ba.gov.br/api/folder',
    // docYearsURL: 'http://desenv.consorciosapi.saude.ba.gov.br/api/document-years',

    // HOMOLOGA
    // apiURL: 'http://homologa.consorciosapi.saude.ba.gov.br/api',
    // baseUrlSite: 'http://homologa.consorciosapi.saude.ba.gov.br/',
    // captchaURL: 'http://homologa.consorciosapi.saude.ba.gov.br/captcha/api/default',
    // docURL: 'http://homologa.consorciosapi.saude.ba.gov.br/api/document',
    // docItemsURL: 'http://homologa.consorciosapi.saude.ba.gov.br/api/folder',
    // docYearsURL: 'http://homologa.consorciosapi.saude.ba.gov.br/api/document-years',

    // // PRODUCAO
    apiURL: 'https://consorciosapi.saude.ba.gov.br/api',
    captchaURL: 'https://consorciosapi.saude.ba.gov.br/captcha/api/default',
    baseUrlSite: 'https://consorciosapi.saude.ba.gov.br/',
    docURL: 'https://consorciosapi.saude.ba.gov.br/api/document',
    docItemsURL: 'https://consorciosapi.saude.ba.gov.br/api/folder',
    docYearsURL: 'https://consorciosapi.saude.ba.gov.br/api/document-years',
    // ID de contratos no BD de Produção é 41 mas em Desenv é 7 
    // por isso a alteraçaõ do ID no codigo para em produção funcionar

    // alterado para homologa pois vai ser renomeado com o nome de homologa

    //// LOCAL
    // apiURL: 'http://localhost:8000/api',
    // baseUrlSite: 'http://localhost:8000/', 
    // // captchaURL: 'http://localhost:8000/captcha/api/default',
    // docURL: 'http://localhost:8000/api/document',
    // docItemsURL: 'http://localhost:8000/api/folder',
    // docYearsURL: 'http://localhost:8000/api/document-years',
}
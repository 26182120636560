<template>
  <v-container class="main">
    <v-sheet
      class="rounded-lg nav d-flex align-center mb-5"
      color="white"
      height="65"
      width="100%"
      ><h4>{{ localizacao }}</h4></v-sheet
    >
    <v-card elevation="0" class="cardConcorcios">
      <div class="textInfo mb-5">
        <h4>Selecione um consórcio</h4>
        <div class="linha"></div>
      </div>
      <v-row>
        <v-col cols="12" md="3" sm="12" v-for="dado in dados" :key="dado.id"
          ><v-card class="cardsDados rounded-lg" elevation="0">
            <div class="box text-center">
              <!-- <img
                v-bind:src="
                  'http://desenv.consorciosapi.saude.ba.gov.br/assets/img/logos/' +
                  dado.image
                "
              /> -->
              <!-- <img v-bind:src="'http://homologa.consorciosapi.saude.ba.gov.br/assets/img/logos/' + dado.image"> -->
              <img v-bind:src="'https://consorciosapi.saude.ba.gov.br/assets/img/logos/' + dado.image">
              <h4 v-text="dado.name"></h4>
            </div>

            <router-link
              :to="{ name: 'FichaTecnica', params: { id: dado.id } }"
              class="text-decoration-none link"
              ><v-btn
                class="btnGo rounded-lg subtitle-2"
                outlined
                color="success"
                elevation="0"
                >Acessar <v-icon>mdi-chevron-right</v-icon>
              </v-btn></router-link
            >
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>




<script>
import axios from "@/axios";

export default {
  data() {
    return {
      localizacao: "Consórcios Públicos Interfederativos de Saúde",
      dados: [],
    };
  },
  name: "MainComp",

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      axios.get(`/consortia`).then((response) => {
        this.dados = response.data;
      });
    },
  },
};


</script>

<style scoped>
.nav {
  padding: 30px;
}

.nav > h4 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;

  color: #000000;
}

.cardConcorcios {
  padding: 30px;
}

.cardConcorcios > .textInfo > h4 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}

.cardConcorcios > .textInfo > .linha {
  background: #18e79a;
  width: 75px;
  height: 4px;
  border-radius: 25px;
}

.cardsDados {
  border: 1px solid #dddddd;
  padding: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding-top: 30px;

  min-height: 280px;
}

.cardsDados:hover {
  border: 1px solid #4caf50;
}

.cardsDados > .box {
  display: flex;
  flex-direction: column;

  align-items: center;

  width: 70%;
  margin-bottom: 20px;
}

.cardsDados > .box > img {
  height: 100px;
  width: 140px;

  margin-bottom: 20px;
}

.cardsDados > .box > h4 {
  font-family: "Poppins";
  font-size: 14px;
  color: #717171;
  font-weight: 500;

  margin-left: 10px;
}

.cardsDados > .link {
  position: absolute;
  bottom: 20px;
}

.btnGo {
  text-transform: none;
}
</style>

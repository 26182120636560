<template>
  <div class="text-center">
    <v-menu 
      :close-on-content-click="false"
      :nudge-width="200"
      >

      <template v-slot:activator="{ on, attrs }">
        <v-avatar class="bgWhite rounded-lg" size="30" v-bind="attrs" v-on="on">
          <v-icon small color="black">mdi-widgets</v-icon>
        </v-avatar>
      </template>

      <v-card class="boxOptions">
          <h3>Menu</h3>

          <v-row v-if="logado == true">

            <v-col cols="12" md="6">
              <div class="listItem">
                <router-link class="link" to="/">
                  <v-icon class="icon" color="black">mdi-web</v-icon>
                  <h3>
                    Consórcios
                  </h3>
                </router-link>
              </div>
            </v-col>

            <v-col cols="12" md="6">
              <div class="listItem">
                <router-link class="link" to="/usuarios">
                  <v-icon class="icon" color="black">mdi-account-multiple</v-icon>
                  <h3>Usuários</h3>
                </router-link>
              </div>
            </v-col>

            <v-col cols="12" md="6">
              <div class="listItem">
                <router-link class="link" to="/meu-perfil">
                  <v-icon class="icon" color="black">mdi-account</v-icon>
                  <h3>Conta</h3>
                </router-link>
              </div>
            </v-col>

            <v-col cols="12" md="6">
              <div class="listItem">
                <div class="link" @click="logout">
                  <v-icon class="icon" color="black">mdi-exit-to-app</v-icon>
                  <h3>Sair</h3>
                </div>
              </div>
            </v-col>

          </v-row>

          <v-row v-else>
            <v-col cols="12" md="12" sm="12" class="cardLink">
              <router-link class="link" to="/login">
                <v-icon class="icone mb-2">mdi-shield-key</v-icon>
                <span>Fazer Login!</span>
              </router-link>
            </v-col>
          </v-row>
      </v-card>

    </v-menu>
  </div>
</template>

<script>
  export default {
    name: "NavMenuComp",

    data() {
      return {
        logado: false
      }
    },

    created() {
      this.initialize();
    },

    methods: {
      initialize() {
        const token = localStorage.getItem('token');

        if(token != null){
          this.logado = true
        }

      },
      logout(){
        localStorage.removeItem('token')
        this.$router.push({ name: 'Login' })
      }
    }
  };


</script>

<style scoped>

  .boxOptions{
    min-height: 150px;
    padding: 25px;
  }

  .boxOptions > h3{
    font-family: 'Poppins';
    font-size: 14px;

    margin-bottom: 10px;
  }

  .listItem{
    border: 1px solid #ebf2fa;
    border-radius: 7px;
    padding: 15px;
  }

  .listItem > .link{
    display: flex;
    flex-direction: column;
    align-items: center;

    text-decoration: none;
    cursor: pointer;
  }

  .listItem > .link > .icon{
    font-size: 22px;
  }

  .listItem > .link > h3{
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 400;
    color: #121212;

  }

  .linkMenu {
    text-decoration: none;
    color: #121212;
  }

  .bgWhite{
    border: 1px solid rgba(0, 0, 0, 0.281)
  }

  .cardLink {
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #9c9c9c;
    height: 80px;
  }

  .link {
    text-decoration: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .icone {
    color: #778ca3
  }

  .link:hover > .icone,
  .link:hover > .span{
    color: #2d98da;
  }

  .link > span {
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 500;
    color: #121212;
  }
</style>
